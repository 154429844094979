import axios from 'axios'

export class ApiClient {
    axios;
    requestQueue = [];
    authErrorHook = null;

    constructor () {
      this.axios = axios.create({
        baseURL: process.env.VUE_APP_AXIOS_BASE_URL || 'http://localhost:3021',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    async request (
      method, url, data, config
    ) {
      return new Promise((resolve, reject) => {
        const isFirstRequest = !this.requestQueue.length
        config = config || {}
        // @ts-ignore
        this.requestQueue.push({
          ...config,
          method,
          url,
          data: data != null ? data : config.data,
          promise: {
            resolve,
            reject
          }
        })

        if (isFirstRequest) {
          this.executeRequest()
        }
      })
    }

    async get (url, config) {
      return this.request('GET', url, null, config)
    }

    async post (url, data, config) {
      return this.request('POST', url, data, config)
    }

    async patch (url, data, config) {
      return this.request('PATCH', url, data, config)
    }

    async put (url, data, config) {
      return this.request('PUT', url, data, config)
    }

    async delete (url, config, data) {
      return this.request('DELETE', url, data, config)
    }

    setupAuthErrorHook = (cb) => {
      this.authErrorHook = cb
    }

    setHeader = (headerName, headerValue) => {
      this.axios.defaults.headers.common[headerName] = headerValue !== null ? headerValue : undefined
    }

    async executeRequest () {
      const request = this.requestQueue.shift()

      if (request == null) {
        return
      }

      const { promise, ...requestConfig } = request

      try {
        promise.resolve(
          await this.axios.request(requestConfig)
        )
      } catch (e) {
        if (this.authErrorHook != null && e.response != null && e.response.status === 401) {
          await this.authErrorHook()
        }
        promise.reject(e)
      }

      this.executeRequest()
    }
}

export const apiClient = new ApiClient()
